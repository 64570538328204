<template>
  <div v-if="!refresh">
    <div
      v-if="!loading && !profilePage && data.properties.filed_content != 'Hide'"
    >
      <div v-if="!isDefaultPos" style="display: flex; flex-direction: column">
        <div style="white-space: nowrap" v-if="!data.properties.hideLabel">
          <label
            for="text"
            v-if="hasLabel && data.properties.filed_content != 'Hide'"
            :style="getLabelStyles"
          >
            {{ label || data.label }}
          </label>
          <span
            v-if="data.validations.required && !isView && hasLabel"
            style="color: red; font-weight: bold; margin-left: 5px"
          >
            *
          </span>
          <span>
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </div>
        <div
          :style="{
            display: 'grid',
            gridTemplateColumns: columnStyles,
            gridGap: `${cellSpacing}px`,
            border: `${borderSize}px solid black`,
          }"
        >
          <div
            v-for="(cell, index) in tableCells"
            :key="index"
            :style="{
              border: `${borderSize}px solid black`,
              padding: `${cellPadding}px`,
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }"
          >
            <div
              v-if="index < columns - 1"
              class="resize-handle"
              @mousedown="startResize($event, index)"
            ></div>
          </div>
        </div>
      </div>
      <el-row align="middle" v-else class="topfield">
        <el-col :span="24">
          <div
            :style="{
              display: 'grid',
              gridTemplateColumns: columnStyles,
              gridGap: `${cellSpacing}px`,
              border: `${borderSize}px solid black`,
            }"
          >
            <div
              v-for="(cell, index) in tableCells"
              :key="index"
              :style="{
                border: `${borderSize}px solid black`,
                padding: `${cellPadding}px`,
                height: '50px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
            >
              {{ cell }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableExecute",
  props: [
    "data",
    "value",
    "isView",
    "loading",
    "profilePage",
    "refresh",
    "isDefaultPos",
    "label",
  ],
  data() {
    return {
      columnWidths: [],
      resizingColumnIndex: null,
      startX: 0,
      startWidths: [],
    };
  },
  computed: {
    hasLabel() {
      return this.data.label || false;
    },
    getLabelStyles() {
      return {
        fontSize: this.data.labelFontSize || "14px",
        fontWeight: this.data.labelFontWeight || "bold",
      };
    },
    columns() {
      return this.data.tableProperties.columns || 3;
    },
    borderSize() {
      return this.data.tableProperties.borderSize || 1;
    },
    cellSpacing() {
      return this.data.tableProperties.cellSpacing || 2;
    },
    cellPadding() {
      return this.data.tableProperties.cellPadding || 5;
    },
    tableCells() {
      return Array.from(
        { length: this.columns * (this.data.tableProperties.rows || 3) },
        (_, i) => `Cell ${i + 1}`
      );
    },
    columnStyles() {
      return this.columnWidths.map((width) => `${width}px`).join(" ");
    },
  },
  mounted() {
    this.initializeColumnWidths();
  },
  methods: {
    startResize(event, index) {
      this.resizingColumnIndex = index;
      this.startX = event.clientX;
      this.startWidths = [...this.columnWidths];
      document.addEventListener("mousemove", this.handleResize);
      document.addEventListener("mouseup", this.stopResize);
    },
    handleResize(event) {
      if (this.resizingColumnIndex === null) return;

      const deltaX = event.clientX - this.startX;
      const currentWidth = this.startWidths[this.resizingColumnIndex];
      let nextWidth = 0;

      if (this.resizingColumnIndex === this.columnWidths.length - 1) {
        nextWidth = 0;
      } else {
        nextWidth = this.startWidths[this.resizingColumnIndex + 1];
      }

      const newCurrentWidth = Math.max(currentWidth + deltaX, 50);
      const newNextWidth = Math.max(nextWidth - deltaX, 50);

      this.$set(this.columnWidths, this.resizingColumnIndex, newCurrentWidth);

      if (this.resizingColumnIndex < this.columnWidths.length - 1) {
        this.$set(
          this.columnWidths,
          this.resizingColumnIndex + 1,
          newNextWidth
        );
      }

      this.$emit("updateColumnWidths", [...this.columnWidths]);
    },
    initializeColumnWidths() {
      if (this.data.tableProperties.columnWidths) {
        this.columnWidths = [...this.data.tableProperties.columnWidths];
      } else {
        const tableWidth = this.$el.offsetWidth;
        const defaultWidth = tableWidth / this.columns;
        this.columnWidths = Array(this.columns).fill(defaultWidth);
      }
    },
    stopResize() {
      this.resizingColumnIndex = null;
      document.removeEventListener("mousemove", this.handleResize);
      document.removeEventListener("mouseup", this.stopResize);
    },
  },
};
</script>

<style scoped>
.topfield {
  margin-top: 10px;
}

.resize-handle {
  position: absolute;
  top: 0;
  right: -5px;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}
</style>
