var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.refresh)?_c('div',[(!_vm.loading && !_vm.profilePage && _vm.data.properties.filed_content != 'Hide')?_c('div',[(!_vm.isDefaultPos)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[(!_vm.data.properties.hideLabel)?_c('div',{staticStyle:{"white-space":"nowrap"}},[(_vm.hasLabel && _vm.data.properties.filed_content != 'Hide')?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(_vm.data.validations.required && !_vm.isView && _vm.hasLabel)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"5px"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_vm._e(),_c('div',{style:({
          display: 'grid',
          gridTemplateColumns: _vm.columnStyles,
          gridGap: `${_vm.cellSpacing}px`,
          border: `${_vm.borderSize}px solid black`,
        })},_vm._l((_vm.tableCells),function(cell,index){return _c('div',{key:index,style:({
            border: `${_vm.borderSize}px solid black`,
            padding: `${_vm.cellPadding}px`,
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          })},[(index < _vm.columns - 1)?_c('div',{staticClass:"resize-handle",on:{"mousedown":function($event){return _vm.startResize($event, index)}}}):_vm._e()])}),0)]):_c('el-row',{staticClass:"topfield",attrs:{"align":"middle"}},[_c('el-col',{attrs:{"span":24}},[_c('div',{style:({
            display: 'grid',
            gridTemplateColumns: _vm.columnStyles,
            gridGap: `${_vm.cellSpacing}px`,
            border: `${_vm.borderSize}px solid black`,
          })},_vm._l((_vm.tableCells),function(cell,index){return _c('div',{key:index,style:({
              border: `${_vm.borderSize}px solid black`,
              padding: `${_vm.cellPadding}px`,
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            })},[_vm._v(" "+_vm._s(cell)+" ")])}),0)])],1)],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }